'use client'

import { useEffect, useState } from 'react'
import { track } from '../utils/api'

const Track = ({ eventName, eventParams }: { eventName: string; eventParams?: { [key: string]: any } }) => {
    const eventParamsJSON = eventParams === undefined ? undefined : JSON.stringify(eventParams)
    const [ignore, setIgnore] = useState(false)
    useEffect(() => {
        // make sure we only call track once
        if (ignore) {
            return
        }
        setIgnore(true)
        // call track
        const eventParams = eventParamsJSON === undefined ? undefined : JSON.parse(eventParamsJSON)
        track(eventName, eventParams)
    }, [eventName, eventParamsJSON, ignore])
    return null
}

export default Track
