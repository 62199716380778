'use client'

import { Popover, PopoverButton, PopoverBackdrop, PopoverPanel } from '@headlessui/react'
import clsx from 'clsx'
import Link from 'next/link'
import { Button } from 'src/components/Button'
import { Container } from 'src/components/Container'
import { Logo } from 'src/components/Logo'
import { NavLink } from 'src/components/NavLink'
import { getSiteUrlPrefix } from '../utils/api'

function MobileNavLink({
    href,
    children,
    disabled,
    onClick,
}: {
    href: string
    children: React.ReactNode
    disabled?: boolean
    onClick?: () => void
}) {
    return (
        <PopoverButton
            as={Link}
            href={disabled ? '#' : href}
            className={clsx(
                'block w-full px-4 hover:bg-neutral-50 active:bg-neutral-100 transition-colors py-[10px] first:pt-4 last:pb-4 first:rounded-t-lg last:rounded-b-lg',
                disabled && 'cursor-not-allowed',
            )}
            onClick={e => {
                if (typeof onClick === 'function') {
                    if (disabled) {
                        e.preventDefault()
                    } else {
                        onClick()
                    }
                }
            }}
        >
            {children}
        </PopoverButton>
    )
}

function MobileNavIcon({ open }: { open: boolean }) {
    return (
        <svg
            aria-hidden="true"
            className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
            fill="none"
            strokeWidth={2}
            strokeLinecap="round"
        >
            <path
                d="M0 1H14M0 7H14M0 13H14"
                className={clsx('origin-center transition', open && 'scale-90 opacity-0')}
            />
            <path
                d="M2 2L12 12M12 2L2 12"
                className={clsx('origin-center transition', !open && 'scale-90 opacity-0')}
            />
        </svg>
    )
}

function SolutionsButton({ open }: { open: boolean }) {
    return (
        <div
            className={clsx(
                'p-2 text-xl rounded-lg flex items-center gap-2 transition-colors ml-[32px]',
                open && 'bg-neutral-100',
            )}
        >
            Solutions
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                className={clsx('transition-transform transform rotate-180', open && 'transform rotate-0')}
            >
                <path d="M4 10L8 6L12 10" stroke="#89919D" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    )
}

const solutions = [
    {
        id: 'students',
        isAvailable: true,
    },
    {
        id: 'professionals',
        isAvailable: false,
    },
    {
        id: 'teams',
        isAvailable: false,
    },
]

export function Header() {
    const renderSolutions = ({ close }: { close: () => void }) => {
        return solutions.map(solution => {
            const disabled = !solution.isAvailable
            return (
                <MobileNavLink key={solution.id} href={`/${solution.id}`} disabled={disabled} onClick={close}>
                    <div className="flex justify-between capitalize items-center">
                        <div className={clsx(disabled && 'opacity-50')}>Circuitly For {solution.id}</div>
                        {disabled && (
                            <div className="py-1 px-2 rounded-full text-xs bg-primary-100 text-primary-500">
                                Coming Soon
                            </div>
                        )}
                    </div>
                </MobileNavLink>
            )
        })
    }
    return (
        <header className="py-10">
            <Container>
                <nav className="relative z-50 flex justify-between">
                    <div className="flex items-center md:gap-x-12">
                        <div className="mr-3 md:hidden">
                            <Popover>
                                <PopoverButton
                                    className="relative z-10 flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none"
                                    aria-label="Toggle Navigation"
                                >
                                    {({ open }) => <MobileNavIcon open={open} />}
                                </PopoverButton>
                                <PopoverBackdrop
                                    transition
                                    className="fixed inset-0 bg-slate-300/50 duration-150 data-[closed]:opacity-0 data-[enter]:ease-out data-[leave]:ease-in"
                                />
                                <PopoverPanel
                                    transition
                                    className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-lg bg-white text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5 data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-150 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in"
                                >
                                    {({ close }) => (
                                        <>
                                            {renderSolutions({ close })}
                                            <hr className="m-2 border-slate-300/40" />
                                            <MobileNavLink href="/login">Sign in</MobileNavLink>
                                        </>
                                    )}
                                </PopoverPanel>
                            </Popover>
                        </div>
                        <Link href="/" aria-label="Home" className="flex flex-row items-center gap-3">
                            <Logo className="h-10 w-auto" />
                            <span className="text-xl font-medium pt-[3px]">Circuitly</span>
                        </Link>
                        <div className="hidden md:flex md:gap-x-6">
                            <Popover>
                                {({ open }) => (
                                    <>
                                        <PopoverButton
                                            className="relative z-10 flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none"
                                            aria-label="Toggle Navigation"
                                        >
                                            <SolutionsButton open={open} />
                                        </PopoverButton>
                                        <PopoverBackdrop
                                            transition
                                            className="fixed inset-0 bg-neutral-200/0 duration-150 data-[closed]:opacity-0 data-[enter]:ease-out data-[leave]:ease-in"
                                        />
                                        <PopoverPanel
                                            transition
                                            className="absolute left-[8.2rem] min-w-96 top-full mt-2 flex origin-top flex-col rounded-lg bg-white text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5 data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-150 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in"
                                        >
                                            {({ close }) => <>{renderSolutions({ close })}</>}
                                        </PopoverPanel>
                                    </>
                                )}
                            </Popover>
                        </div>
                    </div>
                    <div className="flex items-center gap-x-5 md:gap-x-8">
                        <NavLink href={`${getSiteUrlPrefix()}/workspaces`}>
                            <span className="hidden sm:block font-normal text-xl text-neutral-900">Sign in</span>
                        </NavLink>
                        <Button href="/register" color="slate">
                            <span>
                                <span className="font-normal text-xl">
                                    Get started<span className="hidden sm:inline"> for free</span>
                                </span>
                            </span>
                        </Button>
                    </div>
                </nav>
            </Container>
        </header>
    )
}
