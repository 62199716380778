export function Logo(props: React.ComponentPropsWithoutRef<'svg'>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={props.className} viewBox="0 0 32 32" fill="none">
            <path
                d="M32 7.01754V14.0351H28.6316V8.24174C28.6316 5.61404 26.2316 3.36842 23.7053 3.36842H8.36658C5.89474 3.36842 3.36842 5.33333 3.36842 8.42105V24.1404C3.36842 27.2281 5.89474 29.193 8.20283 29.193H23.707C26.386 29.193 28.6316 26.8385 28.6316 24.3122V18.5263H32V24.9825C32 28.9123 28.3509 32 24.9825 32H6.73684C3.08772 32 0 28.0702 0 25.2632V7.01754C0 3.36842 3.36842 0 7.01754 0H24.9825C28.8614 0 32 3.64912 32 7.01754Z"
                fill="#F26D5C"
            />
            <path
                d="M24.7018 14.3158H21.5362C21.2749 13.3437 19.5915 10.6667 16.2807 10.6667C12.9699 10.6667 10.6667 13.4158 10.6667 16.2807C10.6667 19.1456 12.9535 21.8947 16.2807 21.8947C19.6808 21.8947 21.4139 19.0582 21.614 17.9649H24.7018V23.554C24.7018 24.6768 23.8038 25.2632 22.9617 25.2632H8.98246C8.08072 25.2632 7.29825 24.5487 7.29825 23.5789V8.94218C7.29825 8.15329 7.8992 7.29825 9.09612 7.29825H22.9735C24.1404 7.29825 24.7018 8.29345 24.7018 8.94218V14.3158Z"
                fill="#F26D5C"
            />
        </svg>
    )
}
